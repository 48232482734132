import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.accounting', route: { name: 'accounting' } },
      ],
      listRoute: () => ({ name: 'accounting' }),
      afterCreateRoute: result => ({
        name: 'accounting-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.accounting',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              copyable: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            type: {
              label: 'accounting.data.type',
              type: 'readonly-content',
              disabled: true,
              copyable: true,
              containerComponent: 'text',
              text: data => this.vm?.$t(`accounting.data.type.${data}`),
            },
            amount: {
              required: true,
              label: 'data.amount',
              type: 'text',
              copyable: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            order_id: {
              label: 'accounting.data.order_id',
              type: 'text',
              disabled: true,
              copyable: true,
            },
            description: {
              label: 'data.description',
              type: 'textarea',
              maxlength: 500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            note: {
              label: 'data.note',
              type: 'textarea',
              maxlength: 1000,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
      dataAction: {
        delete: {
          confirmIdentity: true,
        },
      },
    }
  }
}

export default new formConfig()
